import { store } from '@/store';

export class MapSettingsControl {
  constructor({ title, store }) {
    this._title = title;
    this._store = store;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-map-outline';
    this._btn.type = 'button';
    this._btn.title = this._title;
    this._btn.onclick = function() {
      if (store.getters['status/showMapSettings']) {
        store.dispatch('status/hideMapSettings');
        _this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-map-outline';
      } else {
        store.dispatch('status/showMapSettings');
        _this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-map';
      }
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  localize({ title }) {
    this._title = title;
    this.updateTitle();
  }

  updateTitle() {
    this._btn.title = this._title;
  }
}
