<template>
  <v-card
    width="200"
    v-if="showMapSettings"
    style="position:absolute; top:10px; left:50px"
    class="mx-auto pa-1"
    tile
  >
    <v-card-title class="primary px-2 py-0" dark>
      {{ $t('Map settings') }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-select
        :items="baseMapStyles"
        :value="baseMapStyles[0]"
        @change="applyBaseMapStyle"
        hide-details
        class="mx-2 my-3"
        :label="$t('Basemap style')"
      ></v-select>

      <v-select
        v-if="styles.length > 1"
        :items="styles"
        v-model="selectedStyleId"
        item-text="name"
        item-value="_id"
        hide-details
        class="mx-2 my-3"
        :label="$t('Floor plan style')"
      ></v-select>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>{{ $t('Raster layers') }}</v-list-item-title>
        </template>
        <v-list dense>
          <v-list-item>
            <v-simple-checkbox v-model="orthoPhoto"></v-simple-checkbox>
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ $t('Orthophoto') }}</span>
                  </template>
                  <span>{{ $t('A global tileset of high-resolution satellite imagery.') }}</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(item, i) in layers" :key="i">
            <v-simple-checkbox v-model="item.enabled"></v-simple-checkbox>
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span v-on="on"> {{ item.name }}</span>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-group>
      <v-checkbox
        hide-details
        class="ma-1 layer-checkbox"
        :label="$t('Rooms layer')"
        v-model="showRooms"
      ></v-checkbox>
      <v-checkbox
        hide-details
        class="ma-1 layer-checkbox"
        :label="$t('Zones layer')"
        v-model="showZones"
      ></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card > .v-card__title {
  color: #fff;
  font-size: 1rem;
}

::v-deep .v-list-group__header {
  padding: 0 8px;
}

::v-deep .layer-checkbox label {
  color: rgba(0, 0, 0, 0.87);
}
</style>

<script>
import { BaseStyle } from '@/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import config from '../helpers/configProvider';
import constants from '~/shared/constants';
import { edges2Rooms } from '~/shared/helpers/rooms';

const basePath = config.getBaseApiServiceUrl();
const prefix_source = 'raster_source_';
const prefix_layer = 'raster_layer_';

export default {
  name: 'MapSettings',

  props: ['map'],

  data() {
    return {
      selectedStyleId: 'default',
      orthoPhoto: false,
      showRooms: false,
      showZones: false,
      layers: []
    };
  },

  computed: {
    ...mapGetters('status', ['showMapSettings', 'editorMode', 'skipApplyStyle']),
    ...mapGetters('site', ['editedSite']),
    ...mapGetters('app', ['additionalLayers']),
    ...mapGetters('map', ['baseMapStyle']),
    ...mapGetters('feature', ['currentFeatures']),
    ...mapGetters('history', ['undoCount']),
    ...mapGetters('level', ['editedLevelId']),

    baseMapStyles() {
      return [
        { text: this.$t(BaseStyle.Standard), value: BaseStyle.Standard },
        { text: this.$t(BaseStyle.Dark), value: BaseStyle.Dark },
        { text: this.$t(BaseStyle.Light), value: BaseStyle.Light }
      ];
    },

    styles() {
      const styles = [
        { name: this.$t('default'), _id: 'default', layers: [...this.additionalLayers] }
      ];

      if (this.editedSite?.styles) {
        styles.push(...this.editedSite.styles);
      }

      return styles;
    }
  },

  mounted() {},

  watch: {
    selectedStyleId() {
      this.applyStyle(this.selectedStyleId);
    },

    styles: {
      handler() {
        this.applyStyle(this.selectedStyleId);
      },
      deep: true
    },

    editorMode(editorMode) {
      if (editorMode) {
        this.showRooms = false;
        this.showZones = false;
      }
    },

    editedLevelId() {
      this.changeZones();
    },

    'editedSite.id'() {
      this.showRooms = false;
      this.showZones = false;
      this.removePreviousSources();
      this.addSources();
    },

    orthoPhoto(value) {
      if (value) {
        this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
      } else {
        this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
      }
    },

    layers: {
      handler() {
        for (let layer of this.layers) {
          if (layer.enabled) {
            this.map.setLayoutProperty(layer.layerId, 'visibility', 'visible');
          } else {
            this.map.setLayoutProperty(layer.layerId, 'visibility', 'none');
          }
        }
      },
      deep: true
    },

    baseMapStyle(value) {
      const layerIds = this.map.getStyle().layers.map((layer) => layer.id);

      for (let layerId of layerIds) {
        if (layerId === 'mapbox-satellite') {
          break;
        }
        this.map.removeLayer(layerId);
      }

      value.layers.forEach((layer) => {
        this.map.addLayer(layer, 'mapbox-satellite');
      });
    },

    showRooms(show) {
      if (show) {
        this.showZones = false;
      }
      this.changeRooms();
    },

    showZones(show) {
      if (show) {
        this.showRooms = false;
      }
      this.changeZones();
    },

    currentFeatures: {
      handler() {
        this.changeRooms();
      },
      deep: true
    },

    undoCount() {
      this.changeZones();
    }
  },

  methods: {
    ...mapMutations('app', ['setLastBaseMapLayerId']),
    ...mapActions('map', ['changeBaseMapStyle']),
    ...mapActions('feature', ['updateCurrentFeatures']),
    ...mapActions('status', ['setCurrentMainMode']),
    ...mapActions('status', ['setSkipApplyStyle']),

    changeRooms() {
      if (this.showRooms) {
        this.setCurrentMainMode(constants.MAIN_MODES.STATIC);
        const edges = this.currentFeatures
          .map(({ feature }) => feature)
          .filter((feature) => feature.properties._type === constants.FEATURE_TYPES.EDGE);

        const rooms = edges2Rooms('', '', {
          type: 'FeatureCollection',
          features: edges
        });

        this.map.getSource('rooms').setData({
          type: 'FeatureCollection',
          features: rooms
        });

        const customImageLayers = this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes('custom-image'));
        if (customImageLayers && customImageLayers.length > 0) {
          const lastCILayerId = customImageLayers[customImageLayers.length - 1].id;
          const lastCILayerIndex = this.map
            .getStyle()
            .layers.findIndex((layer) => layer.id === lastCILayerId);
          this.map.moveLayer('rooms', this.map.getStyle().layers[lastCILayerIndex + 1].id);
        }
      }

      this.map.setLayoutProperty('rooms', 'visibility', this.showRooms ? 'visible' : 'none');
    },

    changeZones() {
      if (this.showZones) {
        this.updateCurrentFeatures(this.editedLevelId);
        this.setCurrentMainMode(constants.MAIN_MODES.STATIC);
        const zones = this.currentFeatures
          .map(({ feature }) => feature)
          .filter((feature) => feature.properties._type === constants.FEATURE_TYPES.ZONE);

        this.map.getSource('zones').setData({
          type: 'FeatureCollection',
          features: zones
        });

        const customImageLayers = this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes('custom-image'));
        if (customImageLayers && customImageLayers.length > 0) {
          const lastCILayerId = customImageLayers[customImageLayers.length - 1].id;
          const lastCILayerIndex = this.map
            .getStyle()
            .layers.findIndex((layer) => layer.id === lastCILayerId);
          this.map.moveLayer('zones-name', this.map.getStyle().layers[lastCILayerIndex + 1].id);
          this.map.moveLayer('zones', this.map.getStyle().layers[lastCILayerIndex + 1].id);
        }
      }

      this.map.setLayoutProperty('zones', 'visibility', this.showZones ? 'visible' : 'none');
      this.map.setLayoutProperty('zones-name', 'visibility', this.showZones ? 'visible' : 'none');
    },

    removePreviousSources() {
      for (let layer of this.layers) {
        this.map.removeLayer(prefix_layer + layer._id);
        this.map.removeSource(prefix_source + layer._id);
      }
      this.layers = [];
    },

    addSources() {
      if (!this.editedSite?.rasterSources || this.editedSite.rasterSources.length < 1) {
        return;
      }

      let layerId;
      for (let source of this.editedSite.rasterSources) {
        const { organizationId } = this.editedSite;
        const sourceId = prefix_source + source._id;
        layerId = prefix_layer + source._id;

        this.map.addSource(sourceId, {
          type: 'raster',
          maxzoom: 20,
          tiles: [`${basePath}/tiles/${organizationId}/${source._id}/?x={x}&y={y}&z={z}`]
        });

        this.map.addLayer({ id: layerId, source: sourceId, type: 'raster' }, 'mapbox-satellite');
        this.map.moveLayer('mapbox-satellite', layerId);
        this.layers.push({ ...source, layerId, enabled: false });
      }

      this.setLastBaseMapLayerId(layerId);
    },

    async applyBaseMapStyle(baseMapStyle) {
      await this.changeBaseMapStyle({ baseMapStyle });
    },

    applyStyle(styleId) {
      if (!this.skipApplyStyle) {
        const mapLayers = this.map.getStyle().layers;

        Object.entries(constants.COLORABLE_LAYERS_MAPPINGS).forEach(
          ([layerIdOnMap, idInCustomStyle]) => {
            let layer = this.styles
              .find(({ _id }) => _id === styleId)
              .layers.find((layer) => layer.id === idInCustomStyle);

            // if layer is not specified in the custom style then use the default layer
            if (!layer) {
              layer = this.additionalLayers.find((layer) => layer.id === idInCustomStyle);
            }

            let { color, opacity } = layer;
            const { type } = mapLayers.find((layer) => layer.id === layerIdOnMap);

            this.map.setPaintProperty(
              layerIdOnMap,
              `${this.getPaintPropertyPrefix(type)}-color`,
              color
            );
            this.map.setPaintProperty(
              layerIdOnMap,
              `${this.getPaintPropertyPrefix(type)}-opacity`,
              opacity
            );
          }
        );
      } else {
        this.setSkipApplyStyle(false);
      }
    },

    getPaintPropertyPrefix(type) {
      if (type === 'symbol') {
        return 'text';
      }
      return type;
    }
  },
  components: {}
};
</script>
