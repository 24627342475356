//import { store } from '../../store';

export class SnapshotControl {
  constructor({ title }) {
    this._title = title;
  }

  onAdd(map) {
    this._map = map;
    //let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-camera-outline';
    this._btn.type = 'button';
    this._btn.title = this._title;
    this._btn.onclick = function() {
      const img = map.getCanvas().toDataURL('image/png');
      const link = document.createElement('a');
      link.href = img;
      link.download = 'map.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  localize({ title }) {
    this._title = title;
    this.updateTitle();
  }

  updateTitle() {
    this._btn.title = this._title;
  }
}
