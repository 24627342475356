import turfPolygonize from '@turf/polygonize';
import turfIntersect from '@turf/intersect';
import turfArea from '@turf/area';
import turfUnion from '@turf/union';

export const edges2Rooms = (siteId, levelId, edgesGeojson) => {
  // get polygons from edge lines
  let polygonsFromWalls = turfPolygonize(edgesGeojson);
  for (let polygonFeatureIdx in polygonsFromWalls.features) {
    let polygonFeature = polygonsFromWalls.features[polygonFeatureIdx];
    let simplePolygon = {
      type: 'FeatureCollection',
      features: [polygonsFromWalls.features[polygonFeatureIdx]]
    };

    // calculate area for later sort
    let metres = turfArea(simplePolygon);
    polygonFeature.properties.area = metres;
  }

  // order by area size desc
  polygonsFromWalls.features.sort(function (feature1, feature2) {
    return feature2.properties.area - feature1.properties.area;
  });

  // go through polygons, find parent and children, depending on intersections
  // - a polygon's chilren are those polygons, that are contained by the inspected polygon
  // - parent attribute points to the smallest polygon that contains the inspected polygon
  for (
    let polygonFeatureIdx = 0;
    polygonFeatureIdx < polygonsFromWalls.features.length;
    polygonFeatureIdx++
  ) {
    // set a unique index on features to identify them when checking parent-children
    polygonsFromWalls.features[polygonFeatureIdx].properties.idx = polygonFeatureIdx;

    if (polygonFeatureIdx != polygonsFromWalls.features.length - 1) {
      // create emmpty children array if not exists
      if (polygonsFromWalls.features[polygonFeatureIdx].properties.children === undefined) {
        polygonsFromWalls.features[polygonFeatureIdx].properties.children = [];
      }

      // check the next, smaller polygons whether they intersect
      for (
        let subPolygonFeatureIdx = polygonFeatureIdx + 1;
        subPolygonFeatureIdx < polygonsFromWalls.features.length;
        subPolygonFeatureIdx++
      ) {
        let subPolygonFeature = polygonsFromWalls.features[subPolygonFeatureIdx];

        if (
          turfIntersect(polygonsFromWalls.features[polygonFeatureIdx], subPolygonFeature) !== null
        ) {
          // update parent's children if a smaller parent found
          if (
            subPolygonFeature.properties.parent !== undefined &&
            subPolygonFeature.properties.parent !== polygonFeatureIdx
          ) {
            const index =
              polygonsFromWalls.features[
                subPolygonFeature.properties.parent
              ].properties.children.indexOf(subPolygonFeatureIdx);
            if (index > -1) {
              polygonsFromWalls.features[
                subPolygonFeature.properties.parent
              ].properties.children.splice(index, 1);
            }
          }

          subPolygonFeature.properties.parent = polygonFeatureIdx;
          polygonsFromWalls.features[polygonFeatureIdx].properties.children.push(
            subPolygonFeatureIdx
          );
        }
      }
    }
  }

  // cut out children (holes) from polygons, save rooms
  // polygonize function creates duplicates of the holes, save their id-s
  let doNotSaveIdxs = [];
  let roomsFeatures = [];

  for (
    let polygonFeatureIdx = 0;
    polygonFeatureIdx < polygonsFromWalls.features.length;
    polygonFeatureIdx++
  ) {
    const polygonFeature = polygonsFromWalls.features[polygonFeatureIdx];

    if (!doNotSaveIdxs.includes(polygonFeature.properties.idx)) {
      let roomPolygonFeature = {
        type: 'Feature',
        properties: {
          name: '',
          onSite: siteId,
          onLevel: levelId
        },
        geometry: {
          type: 'Polygon',
          coordinates: [polygonFeature.geometry.coordinates[0]]
        }
      };

      if (polygonFeature.properties.children) {
        for (let childFeatureIdx of polygonFeature.properties.children) {
          let childFeature = polygonsFromWalls.features[childFeatureIdx];
          roomPolygonFeature.geometry.coordinates.push(
            JSON.parse(JSON.stringify(childFeature.geometry.coordinates[0]))
          );
          doNotSaveIdxs.push(childFeatureIdx);
        }
      }

      roomsFeatures.push(roomPolygonFeature);
    }
  }
  return roomsFeatures;
};

export function calculateFootprintForLevel(rooms, levelId) {
  const roomsOnLevel = rooms.filter((room) => room.properties.onLevel === levelId);
  let footprint = roomsOnLevel[0];
  for (let i = 1; i < roomsOnLevel.length; i++) {
    footprint = turfUnion(footprint, roomsOnLevel[i]);
  }
  footprint.properties.type = 'footprint';
  footprint.properties.levelId = levelId;
  return footprint;
}

export function calculateFootprints(rooms) {
  const levelIds = [...new Set(rooms.map((room) => room.properties.onLevel))]; //use each levelId 1x
  const footprints = [];
  for (const levelId of levelIds) {
    footprints.push(calculateFootprintForLevel(rooms, levelId));
  }
  return footprints;
}
