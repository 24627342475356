import { store } from '../../store';

export class ExtrusionControl {
  constructor({ title3d, title2d }) {
    this._title3d = title3d;
    this._title2d = title2d;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-cube-outline';
    this._btn.type = 'button';
    this._btn.title = this._title3d;
    this._btn.onclick = function() {
      if (store.getters['status/extrusion']) {
        _this._map.flyTo({ pitch: 0 });
      } else {
        _this._map.flyTo({ pitch: 60 });
      }
      store.commit('status/toggleExtrusion');
      _this.updateTitle();
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  localize({ title3d, title2d }) {
    this._title3d = title3d;
    this._title2d = title2d;
    this.updateTitle();
  }

  updateTitle() {
    if (store.getters['status/extrusion']) {
      this._btn.title = this._title2d;
    } else {
      this._btn.title = this._title3d;
    }
  }

  updateButton() {
    if (store.getters['status/extrusion']) {
      this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-cube';
    } else {
      this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-cube-outline';
    }
  }
}
